import logger from '~/services/logger';
import { getURLSegments } from './validations';
import { convertAndParseStringArray } from '~/services/layout/layout';

export const LANGUAGE_SELECTOR_DEFAULT_LANGUAGE = 'en';
export const LANGUAGE_SELECTOR_CLIENT_STORAGE_KEY = 'language';
export const LANGUAGE_SELECTOR_ENDPOINT = '/api/language-selector';

/**
 * getSupportedLanguages returns languages supported by
 * the application defined in the envrionment variable `languages`.
 * In case of exception - function returns English as a default language.
 */
export function getSupportedLanguages(): string[] {
  const languagesString: string | undefined = process?.env?.languages;

  if (!languagesString) return [LANGUAGE_SELECTOR_DEFAULT_LANGUAGE];

  const parseLanguagesString: string[] =
    convertAndParseStringArray(languagesString);

  try {
    if (parseLanguagesString.length === 0)
      throw new Error('Empty production languages array');

    return parseLanguagesString;
  } catch (error: any) {
    logger.info(
      `Failed to parse languages env var, returning [${LANGUAGE_SELECTOR_DEFAULT_LANGUAGE}]\nerror: ${error}`
    );

    return [LANGUAGE_SELECTOR_DEFAULT_LANGUAGE];
  }
}

/**
 * getURLLanguage returns first segment of the URL assuming
 * it's a language code. If the retreived language code is not
 * supported - function returns 'unknown' string.
 */
export function getURLLanguage(pathname: string): string | 'unknown' {
  const segments = getURLSegments(pathname);
  const supportedLanguages = getSupportedLanguages();

  const urlLanguage: string | undefined =
    supportedLanguages.find((lang) => lang === segments[0]) || 'unknown';

  return urlLanguage;
}
